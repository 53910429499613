import { isMobile } from '@/utils/util.js'
export default {
  data() {
    return {
      loading: false,
      sort: null,
      order: null,
      pagination: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        sizes: [10, 20, 50, 100],
        layout: isMobile() ? 'total, prev, pager, next' : 'total, sizes, prev, pager, next, jumper',
        small: isMobile()
      }
    }
  },
  mounted() {
    if (!this.pagination.sizes.includes(this.pagination.pageSize)) {
      console.warn('分页大小只能选择 sizes 中的一个')
    }
  },
  methods: {
    getParams(params = {}) {
      // const baseParams = {
      //   from: (this.pagination.pageNo - 1) * this.pagination.pageSize,
      //   limit: this.pagination.pageSize
      // }
      const baseParams = {
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize
      }
      if (this.sort && this.order) {
        baseParams.sort = this.sort
        baseParams.order = this.order
      }
      return Object.assign({}, params, baseParams)
    },
    onSizeChange(pageSize) {
      this.pagination.pageSize = pageSize
      this.pagination.pageNo = 1
      this.$nextTick(() => {
        this.getDataList()
      })
    },
    onCurrentChange(pageNo) {
      this.pagination.pageNo = pageNo
      this.$nextTick(() => {
        this.getDataList()
      })
    },
    onSortChange(params) {
      if (params.order) {
        this.sort = params.prop
        this.order = params.order === 'ascending' ? 'asc' : 'desc'
      } else {
        this.sort = null
        this.order = null
      }
      this.$nextTick(() => {
        this.getDataList()
      })
    }
  }
}
