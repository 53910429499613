<template>
  <div class="page-body-box">
    <page-main class="code-box" title back>
      <el-tabs v-model="activeTab">
        <el-tab-pane
          v-for="item in tabList"
          :key="item.key"
          :label="item.name"
          :name="item.key"
        />
      </el-tabs>
      <EntrustList v-if="activeTab === 'entrust'"></EntrustList>
      <TrainingList v-if="activeTab === 'training'"></TrainingList>
    </page-main>
  </div>
</template>

<script>
import EntrustList from './components/EntrustList.vue'
import TrainingList from './components/TrainingList.vue'
export default {
  name: 'AccountInviteCode',
  components: {
    EntrustList,
    TrainingList
  },
  data() {
    return {
      activeTab: 'entrust',
      tabList: [
        { name: '学术调研委托服务', key: 'entrust' },
        { name: '员工培训', key: 'training' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  .code-box {
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, .1);
  }

</style>
