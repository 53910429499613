<template>
  <div>
    <div class="nav-wrapper">
      <div class="left">
        <el-input
          v-model="searchParams.title"
          v-debounce="[onSearch, 'input']"
          class="input"
          clearable
          prefix-icon="el-icon-search"
          placeholder="请输入调研名称"
          @clear="onSearch"
          @keydown.enter.native="onSearch"
        />
        <el-select
          v-model="searchParams.isQueryChild"
          class="select"
          placeholder="所有创建者"
          clearable
          @change="onSearch"
        >
          <el-option
            v-for="item in createrList"
            :key="item.id"
            :label="item.label"
            :value="item.id"
          />
        </el-select>
      </div>
      <div class="right">
        <el-button
          v-if="!isMobile"
          class="reset-btn"
          type="primary"
          icon="el-icon-plus"
          @click="onExport"
        >
          下载数据
        </el-button>
      </div>
    </div>

    <el-table
      v-loading="loading"
      :data="academicEntrustList"
      stripe
      style="width: 100%;"
    >
      <el-table-column
        label="调研名称"
        width="200"
        prop="title"
        fixed
        show-overflow-tooltip
      />
      <el-table-column
        label="总问卷数"
        align="center"
        prop="taskNum"
      />
      <el-table-column
        label="已回收"
        align="center"
        prop="finishNum"
      />
      <el-table-column
        label="未开始"
        align="center"
        prop="waitingStartNum"
      />
      <el-table-column
        label="执行中"
        align="center"
        prop="processingNum"
      />
      <el-table-column
        label="已失败"
        align="center"
        prop="failedNum"
      />
      <el-table-column
        label="状态"
        align="center"
        prop="servicePackageStatus"
      >
        进行中
      </el-table-column>
      <el-table-column
        label="创建者"
        align="center"
        width="200"
        prop="orgName"
        show-overflow-tooltip
      />
      <el-table-column
        label="创建时间"
        align="center"
        width="200"
        prop="createdAt"
      />
      <el-table-column
        label="操作"
        align="center"
        width="150"
      >
        <template slot-scope="scope">
          <el-button
            type="text"
            size="small"
            @click="onOperate(scope.row)"
          >
            查看
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      style="margin-top: 20px;"
      class="pagination"
      :page-size="pagination.pageSize"
      :current-page="pagination.pageNo"
      :total="pagination.total"
      :page-sizes="pagination.sizes"
      :layout="pagination.layout"
      :small="pagination.small"
      background
      @size-change="onSizeChange"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>

import paginationMixin from '@/mixins/pagination'
import { isMobile } from '@/utils/util.js'
export default {
  name: 'EntrustList',
  mixins: [paginationMixin],
  data() {
    return {
      loading: false,
      searchParams: {
        title: '',
        reviewStatus: 2,
        isQueryChild: false
      },
      pagination: {
        pageNo: 1,
        pageSize: 10,
        total: 0,
        sizes: [10, 20, 50, 100],
        layout: 'total, sizes, prev, pager, next',
        small: true
      },
      createrList: [
        {
          id: false,
          label: '我创建的'
        },
        {
          id: true,
          label: '其他组织'
        }
      ],
      academicEntrustList: [],
      isExport: false,
      isMobile: isMobile()
    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    // 学术调研委托服务
    getDataList() {
      this.loading = true
      const params = {
        ...this.searchParams,
        pageNo: this.pagination.pageNo,
        pageSize: this.pagination.pageSize
      }
      this.$axios.get(this.$API.getAcademicEntrustList, { params }).then(res => {
        this.loading = false
        this.pagination.total = res.data.totalCount
        this.academicEntrustList = res.data.data
      }).catch(rea => {
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    onSearch() {
      this.getDataList()
    },
    onExport() {
      if (!this.academicEntrustList.length) return this.$message.error('暂无需要导出的数据')
      if (this.isExport) return
      this.isExport = true
      this.$axios.get(this.$API.exportAcademicEntrustList, { params: this.searchParams }).then(res => {
        this.isExport = false
        this.$message.success(res.data)
      }, rea => {
        this.$message.error(rea.message)
      }).catch(() => {
        this.isExport = false
      })
    },
    onOperate(row) {
      this.$router.push(`/entrust/detail/${row.id}`)
    }
  }
}
</script>
<style lang="scss" scoped>
.reset-btn {
  margin-left: 10px;
}
.nav-wrapper {
  display: flex;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    display: block;
  }
  .left {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    margin-bottom: 10px;
    .select {
      width: 120px;
      ::v-deep .el-select__tags {
        >span {
          width: 100%;
          display: grid;
          grid-template-columns: auto auto;
          >span {
            border: none;
            overflow: hidden;
            .el-tag__close.el-icon-close {
              background-color: transparent;
              &:hover {
                background-color: #c0c4cc;
                color: #909399;
              }
            }
          }
        }
      }
    }
    .input {
      width: 160px;
      ::v-deep .el-input__inner {
        background-color: #fafafa;
        border-radius: 20px;
      }
    }
  }
  .right {
    flex-shrink: 0;
    display: flex;
    align-items: flex-start;
    gap: 9px;
  }
}
</style>