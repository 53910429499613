<template>
  <div>
    <div class="table-title">
      <span>进行中的培训任务</span>
      <div v-if="!isMobile">
        <el-button
          class="reset-btn"
          type="primary"
          icon="el-icon-plus"
          :loading="exportLoading"
          @click="exportData"
        >
          下载数据
        </el-button>
      </div>
    </div>
    <el-table
      v-loading="loading"
      :data="inviteCodeTable"
      stripe
      style="width: 100%;"
    >
      <el-table-column
        label="分配时间"
        min-width="100"
        align="left"
        fixed
        prop="buyTime"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.buyTime || '—' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="活动"
        :show-overflow-tooltip="true"
        min-width="100"
        align="center"
        prop="project"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.project || '—' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="type"
        align="center"
        label="任务状态"
      >
        待领取
      </el-table-column>
      <el-table-column
        label="任务积分"
        align="center"
        prop="gift"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.gift || '0' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :show-overflow-tooltip="true"
        min-width="100"
        label="员工"
        align="center"
        prop="representName"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.representName || '—' }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="任务有效期"
        :show-overflow-tooltip="true"
        min-width="100"
        align="center"
        prop="validDay"
      >
        <template slot-scope="scope">
          <span>{{ `${scope.row.representValidDay}天代表执行` }}<br>{{ `${scope.row.validDay}天医生学习` }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="组织"
        :show-overflow-tooltip="true"
        min-width="150"
        align="center"
        prop="finalOrgName"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.finalOrgName || "-" }}</span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      class="sorter-box"
      :current-page="searchData.pageNo"
      :page-size="searchData.pageSize"
      :total="total"
      background
      hide-on-single-page
      small
      layout="total, prev, pager, next"
      @current-change="onCurrentChange"
    />
  </div>
</template>

<script>
import { inviteCodeType } from '@/utils/util'
import { mapActions, mapGetters } from 'vuex'
import {isMobile} from '@/utils/util'
const typeMap = new Map([
  [-1, '医生已领取'],
  ['-1', '医生已领取'],
  [1, '医生待领取'],
  ['1', '医生已领取'],
  [2, '代表未分配'],
  ['2', '代表未分配'],
  [3, '组织未分配'],
  ['3', '组织未分配']
])
export default {
  name: 'TrainingList',
  filters: {
    typeStr(type) {
      return typeMap.get(type) || '—'
    }
  },
  data() {
    return {
      type: 'training',
      tableData: [],
      search: '',
      loading: true,
      // ------------------ table header select ----------------
      options: [
        {
          value: '1',
          label: '医生待领取'
        },
        {
          value: '2',
          label: '代表未分配'
        },
        {
          value: '3',
          label: '组织未分配'
        }
      ],
      // -------------------- 搜索、页数 -----------------
      timerId: null,
      total: 0,
      searchData: {
        pageNo: 1,
        pageSize: 10,
        sceneType: null,
        ownerType: ''
      },
      exportLoading: false,
      isMobile: isMobile()
    }
  },
  computed: {
    ...mapGetters(['inviteCodeTable']),
    initSearchData() {
      return JSON.stringify(this.searchData)
    }
  },
  watch: {
    initSearchData: {
      handler(newVal, oldVal) {
        if (JSON.parse(newVal).pageNo === JSON.parse(oldVal).pageNo) {
          this.$set(this.searchData, 'pageNo', 1)
        }
        clearTimeout(this.timerId)
        this.timerId = setTimeout(() => {
          this.onGetInviteCodeTable()
        }, 500)
      },
      deep: true
    }
  },
  mounted() {
    this.onPageInit()
  },
  methods: {
    ...mapActions(['getInviteCodeTable']),
    onPageInit() {
      this.$set(this.searchData, 'sceneType', inviteCodeType(this.type)[0])
    },
    onGetInviteCodeTable() {
      this.loading = true
      this.getInviteCodeTable(this.searchData).then(res => {
        this.total = res.data.totalCount || 0
        this.loading = false
      }, rea => {
        this.total = 0
        this.loading = false
        this.$message.error(rea.message)
      })
    },
    onSelectChange(val) {
      if (this.searchData.pageNo === 1 && this.searchData.ownerType === val) {
        this.onGetInviteCodeTable()
      }
      this.searchData = Object.assign({}, this.searchData, { ownerType: val, pageNo: 1 })
    },
    onCurrentChange(options) {
      this.$set(this.searchData, 'pageNo', options)
    },
    async exportData() {
      try {
        this.exportLoading = true
        const res = await this.$axios.get(this.$API.codeListExport, {params: this.searchData})
        this.$message.success(res.data)
        this.exportLoading = false
      } catch (error) {
        this.exportLoading = false
        this.$message.error(error.message)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .table-title {
    color: #7784a1;
    padding-bottom: 17px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .sorter-box {
    padding-top: 20px;
    text-align: center;
    background: #fff;
  }

</style>
